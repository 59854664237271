import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    darkGray: '#3F5561',
    lightGray: '#8B929D',
    buttonGray: '#B7B7B7',
    placeholderGray: '#5F6770',
    electric: '#0196AA',
    electricDark: '#017E8E',
    green: '#0BAE88',
    darkGreen: '#016674',
    white: '#FFFFFF',
    error: '#E83D32',
    errorBackground: 'rgba(232, 61, 50, 0.3)',
    blue: '#182F40',
    lightBlue: '#396CD8',
    darkBlue: '#2A519E',
    facebookBlue: '#185DB5',
    facebookBlueHover: '#1877F2',
    black: '#0F1E27',
    darkBlueBackground: '#0F2652',
    backgroundDark: '#061218',
    backgroundGray: '#172126',
    darkText: '#061218',
    grayText: '#f1f1f1',
    checkboxBackground: 'rgba(6, 18, 24, 0.7)',
    checkboxCheckedBackground: 'rgba(111, 72, 26, 0.9)',
    checkboxBorder: 'rgba(255, 255, 255, 0.49)',
    orange: '#E5830E',
    buttonOrangeHover: '#F59C31',
    shinyBlue: '#3058BE',
    blurryBlue: '#173164',
    menuBlue: '#2D54B4',
    accountPageGray: '#152430',
  },
};

export default theme;
