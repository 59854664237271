import React, { useContext, createContext, useState } from 'react';
import JwtDecode from 'jwt-decode';

import { storageSetItem } from 'utils/helpers';
import { STORAGE_KEYS } from 'utils/constants';

interface IUpdateUserInfo {
  refreshToken: string;
  accessToken: string;
}

type TUpdateUserInfo = (value: IUpdateUserInfo) => void;

export enum PAYMENT_PROVIDERS {
  APPLE = 'Apple',
  GOOGLE = 'Google',
  STRIPE = 'Stripe',
}

export interface ISubscriptionData {
  id: string;
  provider_product: {
    id: string;
    product: {
      id: string;
      name: string;
      description: string;
      price: string;
    };
    provider: {
      id: string;
      name: PAYMENT_PROVIDERS;
    };
    external_id: string;
    metadata: null;
  };
  status: string;
  external_id: string;
  period_start: string;
  period_end: string;
  in_grace_period: string;
  auto_renew: boolean;
}

export interface IOrganization {
  name: string;
  license_limit: number;
  license_count: number;
}

export interface IUser {
  token_type: string;
  exp: string;
  jti: string;
  user: {
    id: string;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    questions_answered: boolean;
    is_verified: boolean;
    subscription: ISubscriptionData | null;
    organization: IOrganization;
  };
}

type TUserInfo = IUpdateUserInfo & { info: IUser | null };

const UserInfoContext = createContext<null | TUserInfo>(null);
const UserInfoUpdateContext = createContext<TUpdateUserInfo>(() => '');

export const useUserInfo = () => useContext(UserInfoContext);
export const useUpdateUserInfo = () => useContext(UserInfoUpdateContext);

const AccessTokenStateProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<TUserInfo | null>(null);

  const handleUserInfoChange = ({ accessToken, refreshToken }: IUpdateUserInfo) => {
    const userInfo = accessToken ? JwtDecode<IUser>(accessToken) : null;

    setUserInfo({
      accessToken,
      refreshToken,
      info: userInfo ? userInfo : null,
    });

    if (refreshToken) {
      storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    }
  };

  return (
    <UserInfoContext.Provider value={userInfo}>
      <UserInfoUpdateContext.Provider value={handleUserInfoChange}>
        {children}
      </UserInfoUpdateContext.Provider>
    </UserInfoContext.Provider>
  );
};

export default AccessTokenStateProvider;
