export const BREAKPOINTS = {
  1450: '@media (max-width: 1450px)',
  1350: '@media (max-width: 1350px)',
  1320: '@media (max-width: 1320px)',
  1200: '@media (max-width: 1200px)',
  1150: '@media (max-width: 1150px)',
  1100: '@media (max-width: 1100px)',
  955: '@media (max-width: 955px)',
  870: '@media (max-width: 870px)',
  800: '@media (max-width: 800px)',
  500: '@media (max-width: 500px)',
  440: '@media (max-width: 440px)',
};

export const WEB_SIDE_PADDING = '120px';
export const MOBILE_SIDE_PADDING = '20px';

export const isDev = process.env.NODE_ENV === 'development';

export const ERROR_TEXTS = {
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  EMAIL_PASSWORD_REQUIRED: 'Email and password are required',
  BLANK_FIELD: 'This field may not be blank',
  VALID_EMAIL: 'Please provide a valid email',
  PASSWORD_VALIDATION_ERROR:
    'Password has to be 9 characters long, must contain upper case and at least 1 digit',
  SOMETHING_WENT_WRONG: 'Something went wrong. Please try again later.',
};

export enum STORAGE_KEYS {
  REFRESH_TOKEN = 'refresh_token',
  COUPON = 'coupon',
  USER_ANSWERS = 'user_answers',
}

export enum PAYMENT_PROCESSORS {
  PAYPAL = 'Paypal',
  STRIPE = 'Stripe',
}

export const EXTERNAL_LINKS = {
  APP_STORE: 'https://apps.apple.com/us/app/yours-app/id1543920949',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.yoursapp',
  GET_APP: 'https://yoursapp.com/get-app',
  TERMS_AND_CONDITIONS: 'https://yoursapp.com/legal/terms-and-conditions',
  PRIVACY_POLICY: 'https://yoursapp.com/legal/privacy-policy',
  WEB_APP: 'https://app.yoursapp.com/',
};

export enum SOCIAL_SIGNIN_PROVIDER {
  GOOGLE_OAUTH2 = 'google-oauth2',
  FACEBOOK = 'facebook',
}

export enum QUERY_PARAMS {
  TOKEN = 'token',
  CODE = 'code',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
  LICENSE_ID = 'license_id',
  GCLID = 'gclid',
  FBCLID = 'fbclid',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  IMPACT_CLICK_ID = 'irclickid',
  SKIP_CHECKOUT = 'skip_checkout',
}

export enum ROUTES {
  MAIN = '/',
  REGISTER = '/register',
  SUMMARY = '/summary',
  CHECKOUT = '/checkout',
  PERSONALIZING = '/personalizing',
  PURCHASE_SUCCESS = '/purchase-success',
}

export const FACEBOOK_LOGIN = 'https://join.yoursapp.com/facebook-login';
export const GOOGLE_LOGIN = 'https://join.yoursapp.com/google-login';
export const SOCIAL_AUTH_LINK = 'https://api.yoursapp.com/v1/auth/social/login/';
export const FACEBOOK_AUTH_LINK = `${SOCIAL_AUTH_LINK}?provider=${SOCIAL_SIGNIN_PROVIDER.FACEBOOK}&redirect_url=${FACEBOOK_LOGIN}`;
export const GOOGLE_AUTH_LINK = `${SOCIAL_AUTH_LINK}?provider=${SOCIAL_SIGNIN_PROVIDER.GOOGLE_OAUTH2}&redirect_url=${GOOGLE_LOGIN}`;

export const YOURS_APP_DOMAIN = '.yoursapp.com';
