exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-black-friday-tsx": () => import("./../../../src/pages/black-friday.tsx" /* webpackChunkName: "component---src-pages-black-friday-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-cyber-monday-tsx": () => import("./../../../src/pages/cyber-monday.tsx" /* webpackChunkName: "component---src-pages-cyber-monday-tsx" */),
  "component---src-pages-facebook-login-tsx": () => import("./../../../src/pages/facebook-login.tsx" /* webpackChunkName: "component---src-pages-facebook-login-tsx" */),
  "component---src-pages-google-login-tsx": () => import("./../../../src/pages/google-login.tsx" /* webpackChunkName: "component---src-pages-google-login-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mental-health-week-tsx": () => import("./../../../src/pages/mental-health-week.tsx" /* webpackChunkName: "component---src-pages-mental-health-week-tsx" */),
  "component---src-pages-personalizing-tsx": () => import("./../../../src/pages/personalizing.tsx" /* webpackChunkName: "component---src-pages-personalizing-tsx" */),
  "component---src-pages-purchase-success-tsx": () => import("./../../../src/pages/purchase-success.tsx" /* webpackChunkName: "component---src-pages-purchase-success-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */)
}

