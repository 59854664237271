import React, { useContext, createContext, useState } from 'react';

import { PAYMENT_PROCESSORS } from 'utils/constants';

export interface IPricingStateProvider {
  appliedCoupon: string;
  selectedPlanId: string;
  selectedPlanPrice: string;
  stripeProductIdBySelectedPlan?: string;
  paypalProductIdBySelectedPlan?: string;
  trialLength: number;
  selectedPlanBillingPeriod: string | null;
  plans: IPlan[] | null;
  setSelectedPlanId: (value: string) => void;
  setTrialLength: (value: number) => void;
  setAppliedCoupon: (value: string) => void;
  resetPlans: () => void;
  setPlans: (value: IPlan[]) => void;
}

export interface IProviderProduct {
  id: string;
  provider: {
    id: string;
    name: string;
  };
  external_id: string;
  metadata: null;
}

export interface IPlan {
  id: string;
  name: string;
  price: number;
  full_price: number;
  monthly_price: number;
  billing_period: string;
  discount_percent: 0;
  provider_product: IProviderProduct[];
}

const PricingContext = createContext({} as IPricingStateProvider);

const NO_PRICE_VALUE = '-.--';
const NO_TEXT_VALUE = '-';

export const usePricing = () => useContext(PricingContext);

const PricingStateProvider: React.FC = ({ children }) => {
  const [appliedCoupon, setAppliedCoupon] = useState<string>('');
  const [selectedPlanId, setSelectedPlanId] = useState<string>('');
  const [trialLength, setTrialLength] = useState<number>(7);
  const [plans, setPlans] = useState<IPlan[] | null>(null);

  const findPlan = (plan: IPlan) => plan.id === selectedPlanId;
  const findPaypalInfo = (providerProduct: IProviderProduct) =>
    providerProduct?.provider?.name === PAYMENT_PROCESSORS.PAYPAL;
  const findStripeInfo = (providerProduct: IProviderProduct) =>
    providerProduct?.provider?.name === PAYMENT_PROCESSORS.STRIPE;

  const stripeProductIdBySelectedPlan = plans
    ?.find(findPlan)
    ?.provider_product?.find(findStripeInfo)?.id;

  const paypalProductIdBySelectedPlan = plans
    ?.find(findPlan)
    ?.provider_product?.find(findPaypalInfo)?.id;

  const selectedPlanPrice = plans?.find(findPlan)?.price?.toFixed(2) || NO_PRICE_VALUE;
  const selectedPlanBillingPeriod = plans?.find(findPlan)?.billing_period || NO_TEXT_VALUE;

  const resetPlans = () => {
    setPlans(null);
    setSelectedPlanId('');
  };

  return (
    <PricingContext.Provider
      value={{
        selectedPlanBillingPeriod,
        selectedPlanPrice,
        appliedCoupon,
        selectedPlanId,
        plans,
        stripeProductIdBySelectedPlan,
        paypalProductIdBySelectedPlan,
        trialLength,
        setTrialLength,
        setPlans,
        setSelectedPlanId,
        setAppliedCoupon,
        resetPlans,
      }}
    >
      {children}
    </PricingContext.Provider>
  );
};

export default PricingStateProvider;
