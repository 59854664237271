import React from 'react';
import { Global, css, ThemeProvider } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

import theme from 'theme';

import 'assets/fonts/index.css';

const GlobalStyleWrapper: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        ${emotionNormalize}
        html,
        body {
          padding: 0;
          margin: 0;
          min-height: 100%;
          font-family: Poppins;
          background-color: ${theme.colors.backgroundDark};
        }
      `}
    />
    {children}
  </ThemeProvider>
);

export default GlobalStyleWrapper;
