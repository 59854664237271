import { navigate } from 'gatsby';

import { STORAGE_KEYS } from './constants';
import { setCookie, COOKIE_KEY } from './cookies';

export const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

export const isPasswordValid = (password: string) =>
  password.length > 8 && password.toLocaleLowerCase() !== password && /\d/.test(password);

export const storageSetItem = (key: STORAGE_KEYS, value: string) => {
  localStorage.setItem(key, value);
};

export const storageRemoveItem = (key: STORAGE_KEYS) => {
  localStorage.removeItem(key);
};

export const storageGetItem = (key: STORAGE_KEYS) => {
  return localStorage.getItem(key);
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

interface IQueryParams {
  fbclid: string | null;
  gclid: string | null;
  coupon: string | null;
  tuneOfferId: string | null;
  tuneTransactionId: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  impactClickId: string | null;
  skipCheckout: string | null;
}

export const loadQueryParamsToCookies = ({
  fbclid,
  gclid,
  coupon,
  tuneOfferId,
  tuneTransactionId,
  utmCampaign,
  utmMedium,
  utmSource,
  impactClickId,
  skipCheckout,
}: IQueryParams) => {
  if (fbclid) {
    setCookie(COOKIE_KEY.FBCLID, fbclid);
  }

  if (gclid) {
    setCookie(COOKIE_KEY.GCLID, gclid);
  }

  if (coupon) {
    setCookie(COOKIE_KEY.COUPON, coupon);
  }

  if (tuneOfferId) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, tuneOfferId);
  }

  if (tuneTransactionId) {
    setCookie(COOKIE_KEY.TUNE_TRANSACTION_ID, tuneTransactionId);
  }

  if (utmCampaign) {
    setCookie(COOKIE_KEY.UTM_CAMPAIGN, utmCampaign);
  }

  if (utmMedium) {
    setCookie(COOKIE_KEY.UTM_MEDIUM, utmMedium);
  }

  if (utmSource) {
    setCookie(COOKIE_KEY.UTM_SOURCE, utmSource);
  }

  if (impactClickId) {
    setCookie(COOKIE_KEY.IMPACT_CLICK_ID, impactClickId);
  }

  if (skipCheckout) {
    setCookie(COOKIE_KEY.SKIP_CHECKOUT, skipCheckout);
  }
};

export const navigateWithQueryParams = (to: string) => {
  const queryParams = typeof window !== 'undefined' ? window.location.search : '';

  navigate(`${to}${queryParams}`);
};

export const getQueryParamValue = (key: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return new URLSearchParams(window.location.search).get(key);
};

export const getCurrentPathName = () =>
  typeof window !== 'undefined' ? window.location.pathname : null;
