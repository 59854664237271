import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';

import { getCookie, COOKIE_KEY } from 'utils/cookies';
import { useUpdateUserInfo } from 'state/UserInfoStateProvider';
import { storageGetItem, storageRemoveItem, loadQueryParamsToCookies } from 'utils/helpers';
import { QUERY_PARAMS, STORAGE_KEYS } from 'utils/constants';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { ILoginApiResponse, IMarketingProps } from 'features/Register/RegisterForm';
import { usePricing } from 'state/PricingStateProvider';

interface IRefreshTokenApiRequest {
  refresh: string;
}

interface IAutoAuthWrapper {
  children: React.ReactNode;
  location: Location;
}

const AutoAuthWrapper: React.FC<IAutoAuthWrapper> = ({ children, location }) => {
  const updateUserInfo = useUpdateUserInfo();
  const { setAppliedCoupon } = usePricing();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const fbclid = params.get(QUERY_PARAMS.FBCLID);
    const gclid = params.get(QUERY_PARAMS.GCLID);
    const coupon = params.get(QUERY_PARAMS.COUPON);
    const tuneOfferId = params.get(QUERY_PARAMS.TUNE_OFFER_ID);
    const tuneTransactionId = params.get(QUERY_PARAMS.TUNE_TRANSACTION_ID);
    const utmCampaign = params.get(QUERY_PARAMS.UTM_CAMPAIGN);
    const utmMedium = params.get(QUERY_PARAMS.UTM_MEDIUM);
    const utmSource = params.get(QUERY_PARAMS.UTM_SOURCE);
    const impactClickId = params.get(QUERY_PARAMS.IMPACT_CLICK_ID);
    const skipCheckout = params.get(QUERY_PARAMS.SKIP_CHECKOUT);

    const lastCouponFromCookies = getCookie(COOKIE_KEY.COUPON);

    loadQueryParamsToCookies({
      fbclid,
      gclid,
      coupon,
      tuneOfferId,
      tuneTransactionId,
      utmCampaign,
      utmMedium,
      utmSource,
      impactClickId,
      skipCheckout,
    });

    if (coupon) {
      setAppliedCoupon(coupon);
    }

    if (!coupon && lastCouponFromCookies) {
      setAppliedCoupon(lastCouponFromCookies);
    }

    const refreshToken = storageGetItem(STORAGE_KEYS.REFRESH_TOKEN);

    if (!refreshToken) {
      updateUserInfo({ accessToken: '', refreshToken: '' });

      return;
    }

    callCoreApi<IRefreshTokenApiRequest & IMarketingProps>({
      method: METHODS.POST,
      url: API_URLS.REFRESH_TOKEN,
      data: {
        refresh: refreshToken,
        fbclid: fbclid || '',
        gclid: gclid || '',
        campaign: utmCampaign || '',
        medium: utmMedium || '',
        source: utmSource || '',
        tune_offer_id: tuneOfferId || '',
        tune_transaction_id: tuneTransactionId || '',
        impact_click_id: impactClickId || '',
        fbp: getCookie(COOKIE_KEY._FBP),
        fbc: getCookie(COOKIE_KEY._FBC),
        google_cid: getCookie(COOKIE_KEY.YOURS_CID),
        appsflyer_web_id: getCookie(COOKIE_KEY.AF_USER_ID),
      },
    })
      .then((response: AxiosResponse<ILoginApiResponse>) => {
        updateUserInfo({
          accessToken: response.data.access,
          refreshToken: response.data.refresh,
        });
      })
      .catch(() => {
        updateUserInfo({ accessToken: '', refreshToken: '' });
        storageRemoveItem(STORAGE_KEYS.REFRESH_TOKEN);
      });
  }, []);

  return <>{children}</>;
};

export default AutoAuthWrapper;
