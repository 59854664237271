import axios from 'axios';

import { CORE_API, API_URLS, METHODS } from './constants';

interface IQueryParameter {
  key: string;
  value: string;
}

interface ICallApiProps<T> {
  method: METHODS;
  url: API_URLS;
  data?: T;
  queryParams?: IQueryParameter[];
  path?: string;
  accessToken?: string | null;
}

const callCoreApi = <T>({
  method,
  url,
  data,
  queryParams = [],
  path = '',
  accessToken,
}: ICallApiProps<T>) => {
  const params = queryParams
    .map(
      (parameter, index) =>
        `${
          parameter.value
            ? `${index === 0 ? '?' : '&'}${parameter.key}=${encodeURIComponent(parameter.value)}`
            : ''
        }`
    )
    .join('');

  return axios({
    method,
    url: `${CORE_API}${url}${path}${params?.length ? params : ''}`,
    data,
    headers: accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });
};

export default callCoreApi;
