const React = require('react');

const PageWrapper = require('./src/features/AutoAuthWrapper').default;
const GlobalStyleWrapper = require('./src/components/Layout/GlobalStyleWrapper').default;
const UserAnswersStateProvider = require('./src/state/UserAnswersStateProvider').default;
const UserInfoStateProvider = require('./src/state/UserInfoStateProvider').default;
const PricingStateProvider = require('./src/state/PricingStateProvider').default;

export const wrapRootElement = ({ element }) => (
  <UserInfoStateProvider>
    <UserAnswersStateProvider>
      <PricingStateProvider>
        <GlobalStyleWrapper>{element}</GlobalStyleWrapper>
      </PricingStateProvider>
    </UserAnswersStateProvider>
  </UserInfoStateProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props}>{element}</PageWrapper>
);
