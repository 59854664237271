import React, { useContext, createContext, useState } from 'react';
import { keys } from 'ramda';

import { storageSetItem, storageRemoveItem, storageGetItem } from 'utils/helpers';
import { STORAGE_KEYS } from 'utils/constants';

export interface IQuestionAndAnswer {
  [key: string]: string[];
}

export interface IQuestionAndAnswerApiObject {
  id: string;
  answers: {
    id: string;
  }[];
}

export interface IUserAnswersStateProvider {
  answersById: IQuestionAndAnswer;
  getAnswersByIdFromStorage: () => IQuestionAndAnswer;
  updateAnswers: (userAnswers: IQuestionAndAnswer) => void;
  deleteAnswers: () => void;
  storeAnswers: () => void;
  getFormattedAnswers: () => IQuestionAndAnswerApiObject[];
}

const UserAnswersContext = createContext({} as IUserAnswersStateProvider);

export const useUserAnswersContext = () => useContext(UserAnswersContext);

const UserAnswersStateProvider: React.FC = ({ children }) => {
  const [userAnswers, setUserAnswers] = useState<IQuestionAndAnswer>({});

  const handleUserAnswerChange = (answer: IQuestionAndAnswer) => {
    setUserAnswers({
      ...userAnswers,
      ...answer,
    });
  };

  const handleUserAnswerDelete = () => {
    setUserAnswers({});
    storageRemoveItem(STORAGE_KEYS.USER_ANSWERS);
  };

  const handleUserAnswersStore = () => {
    storageSetItem(STORAGE_KEYS.USER_ANSWERS, JSON.stringify(userAnswers));
  };

  const getFormattedUserAnswers = () => {
    const userAnswersFromStorage = JSON.parse(storageGetItem(STORAGE_KEYS.USER_ANSWERS) || '{}');
    const userAnswersValue = keys(userAnswers).length
      ? userAnswers
      : keys(userAnswersFromStorage).length
      ? userAnswersFromStorage
      : {};

    return keys(userAnswersValue).map((questionId: string) => ({
      id: questionId,
      answers: userAnswersValue?.[questionId]?.map((answerId) => ({ id: answerId })),
    }));
  };

  const getAnswersByIdFromStorage = () => {
    const userAnswersFromStorage = JSON.parse(storageGetItem(STORAGE_KEYS.USER_ANSWERS) || '{}');

    return keys(userAnswers).length
      ? userAnswers
      : keys(userAnswersFromStorage).length
      ? userAnswersFromStorage
      : {};
  };

  return (
    <UserAnswersContext.Provider
      value={{
        answersById: userAnswers,
        getAnswersByIdFromStorage: getAnswersByIdFromStorage,
        updateAnswers: handleUserAnswerChange,
        deleteAnswers: handleUserAnswerDelete,
        storeAnswers: handleUserAnswersStore,
        getFormattedAnswers: getFormattedUserAnswers,
      }}
    >
      {children}
    </UserAnswersContext.Provider>
  );
};

export default UserAnswersStateProvider;
